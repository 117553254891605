import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/layout"
import IconIn from "../assets/linkedin.svg"
import Button from "../components/button"

const OurPeoplePage = () => {

  return (
    <Layout>
      <section className="relative bg-white py-8 md:py-12">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-none md:grid-flow-col gap-6 md:gap-24 items-center">
            <StaticImage src="../images/budiyanto_300.jpg" className="flex-shrink-0 mx-auto sm:mx-0" layout="fixed" alt="Budiyanto" />
            <div className="text-center md:text-left">
              <h4 className="text-xl md:text-4xl font-semibold mb-1">Budiyanto</h4>
              <h4 className="text-primary text-lg md:text-2xl font-semibold mb-2">CEO</h4>
              <p className="text-sm md:text-base mb-3 md:mb-6">
                Budiyanto has 10 years experience in retail, business development, and M&A. He started his career at Northstar Pacific (2007-2009).  
                He joined Sigmantara Alfindo, the parent company of Alfamart, and served as a board member of Alfamart since 2010. In addition, he also served as board member in several other business units of Sigmantara Alfindo.
                He holds a Bachelor of Business Administration (Finance) from San Francisco State University and a Master of Business Administration (Finance) from University of San Francisco.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-white py-8 md:py-12">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-none md:grid-flow-col gap-6 md:gap-24 items-center">
            <StaticImage src="../images/artha_300.jpg" className="flex-shrink-0 mx-auto sm:mx-0" layout="fixed" alt="Artha" />
            <div className="text-center md:text-left">
              <h4 className="text-xl md:text-4xl font-semibold mb-1">Gede Artha A. Prana</h4>
              <h4 className="text-primary text-lg md:text-2xl font-semibold mb-2">VP, Research & Development</h4>
              <p className="text-sm md:text-base mb-3 md:mb-6">
                Artha has more than 10 years of work experience in data science and software engineering roles, along with research publications related to software engineering analytics. 
                He holds a Bachelor of Engineering (Honours) in Computer Engineering from Nanyang Technological University, a Master of Technology in Knowledge Engineering from National University of Singapore, 
                and a Ph.D. in Computer Science from Singapore Management University.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-white py-8 md:py-12">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-none md:grid-flow-col gap-6 md:gap-24 items-center">
            <StaticImage src="../images/catherine_300.jpg" className="flex-shrink-0 mx-auto sm:mx-0" layout="fixed" alt="Artha" />
            <div className="text-center md:text-left">
              <h4 className="text-xl md:text-4xl font-semibold mb-1">Catherine Iskandar</h4>
              <h4 className="text-primary text-lg md:text-2xl font-semibold mb-2">Head of Finance</h4>
              <p className="text-sm md:text-base mb-3 md:mb-6">
              Catherine joined Digital Place Ventures Pte Ltd (DPV) in 2022 as the Head of Finance. 
              Before joining DPV, Catherine had 5 years experience with Singapore Tourism Board and 9 years experience in Finance for retail and e-commerce industry. 
              In her current position, Catherine is responsible for all the financial operations in DPV. 
              She holds a Bachelor's Degree in Accounting from Tarumanagara University and a Master of Science in Financial Management from University of East London.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-primary py-8 md:py-20">
        <div className="absolute top-0 left-0 right-0 mx-auto text-center">
          <StaticImage src="../images/home-section-bg-2.png" alt="section"  />
        </div>
      </section>
    </Layout>
  )
}

export default OurPeoplePage

export const Head = () => (
  <>
    <title>DPV - Management</title>
    <meta name="description" content="With a dedicated team of people, DPV has been able to flourish in within the tech industry through its leadership and experience." />
  </>
)

import React from "react"
import { Link } from "gatsby"
import cx from "clsx"

export default function Button({type = 'link', link, outlined, children}) {
  return (
   <>
    {
      type !== 'link' ? (
         <button
          type={type}
          className={cx(
            'inline-flex items-center bg-primary py-2 px-4',
            'text-sm md:text-base text-white',
            'hover:bg-secondary',
            'transition-colors duration-300 ease-in-out'
          )}
        >
          {children}
        </button>
      ) : (
        <Link
          to={link}
          className={cx(
            'inline-flex items-center py-2 px-4',
            'text-sm md:text-base text-white',
            'transition-colors duration-300 ease-in-out',
            [outlined ? 'bg-transparent border border-white' : 'bg-primary hover:bg-secondary']
          )}
        >
          {children}
        </Link>
      )
    }
   </>
  )
}